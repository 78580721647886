import Nav from 'react-bootstrap/Nav';
import './menu.css';

function Menu() {

    return (
        <Nav>

          <Nav.Item>
            <Nav.Link href="/">Reporte</Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/scores">Scores</Nav.Link>
          </Nav.Item>

        </Nav>


      );
}

export default Menu;