

import Formulario from "../formulario/formulario";


function Inicio() {
    return (


            <Formulario/>

        
    )
}

export default Inicio;