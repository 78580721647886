import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import './scores.css';
import { useState, useEffect } from 'react';

import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

const Scores = () => {
    const [edad, setEdad] = useState('00');
    const [frecuenciaCardiaca, setFrecuenciaCardiaca] = useState('');
    const [tensionArterial, setTensionArterial] = useState('00');
    const [creatinina, setCreatinina] = useState('00');
    const [kimball, setKimball] = useState('00');
    const [cardioRespiratorioIngreso, setCardioRespiratorioIngreso] = useState(false);
    const [elevacionEnzimasCardiacas, setElevacionEnzimasCardiacas] = useState(false);
    const [desviacionSegmentoST, setDesviacionSegmentoST] = useState(false);

    const [baseline, setBaseline] = useState('00');
    const [totalPuntos, setTotalPuntos] = useState('00');

    // Crusade
    const [totalPuntosCrusade, setTotalPuntosCrusade] = useState('00');
    const [creatinine, setCreatinine] = useState('00');
    const [heartrate, setHeartrate] = useState('00');
    const [sex, setSex] = useState(false);
    const [chf, setChf] = useState(false);
    const [priorVascularDisease, setPriorVascularDisease] = useState(false);
    const [diabetes, setDiabetes] = useState(false);
    const [systolicBlood, setSystolicBlood] = useState('00');

    // Heart Score
    const [totalPuntosHeartScore, setTotalPuntosHeartScore] = useState('00');
    const [anamnesis, setAnamnesis] = useState('00');
    const [ecg, setEcg ] = useState('00');
    const [age, setAge] = useState('00');
    const [riskFactors, setRiskFactors] = useState('00');
    const [troponin, setTroponin] = useState('00');


    useEffect(() => {
      recalcularPuntos();
      recalcularPuntosCrusade();
      recalcularPuntosHeartScore();
    }, 
    [ edad, frecuenciaCardiaca, tensionArterial, creatinina, kimball, cardioRespiratorioIngreso, elevacionEnzimasCardiacas, desviacionSegmentoST, totalPuntos, 
      baseline, creatinine, heartrate, sex, chf, priorVascularDisease, diabetes, systolicBlood, // Crusade
      anamnesis, ecg, age, riskFactors, troponin // Heart Score
    ] )


    /************** [GRACE] **************/

    const edadHandle = event => {
        setEdad(event.target.value );
    }

    const frecuenciaCaridacaHandle = event => {
        setFrecuenciaCardiaca( event.target.value );
    }

    const tensionArterialHandle = event => {
        setTensionArterial(event.target.value )
    }

    const creatininaHandle = event => {
        setCreatinina(event.target.value )
    }

    const kimballHandle = event => {
        setKimball(event.target.value )
    }

    const desviacionSegmentoSTHandle = event => {
        setDesviacionSegmentoST(event.target.checked )
    }
    
    const elevacionEnzimasCardiacasHandle = event => {
        setElevacionEnzimasCardiacas(event.target.checked )
    }
    
    const cardioRespiratorioIngresoHandle = event => {
        // console.log("Evento cardio respiratorio" + event.target.checked);
        setCardioRespiratorioIngreso(event.target.checked )
    }

    function recalcularPuntos() {

      console.log( "Function - RecalcularPuntos");

      let subFrecuenciaCardiaca = 0;
      let subPregCardioRespiratorio = 0;
      let subPregElevacionEnzimas = 0;
      let subDesviacionSegmento = 0;

      /**** [PUNTOS FRECUENCIA CARDIACA] ****/
      switch(true) {
          case (frecuenciaCardiaca < 70):
            subFrecuenciaCardiaca = 0;
            break;

          case (frecuenciaCardiaca <= 89): 
            subFrecuenciaCardiaca = 7;
            break;

          case (frecuenciaCardiaca <= 109): 
            subFrecuenciaCardiaca = 13;
            break;

          case (frecuenciaCardiaca <= 149): 
            subFrecuenciaCardiaca = 23;
            break;

            case (frecuenciaCardiaca <= 199): 
            subFrecuenciaCardiaca = 36;
            break;

          case (frecuenciaCardiaca >= 200): 
            subFrecuenciaCardiaca = 46;
            break;   
      }

      if( cardioRespiratorioIngreso ) {
        subPregCardioRespiratorio = 43
      }

      if( elevacionEnzimasCardiacas ) {
        subPregElevacionEnzimas = 15
      }

      if( desviacionSegmentoST ) {
        subDesviacionSegmento = 30
      }

      let subTotal = parseInt(edad) + parseInt(tensionArterial) + parseInt(creatinina) + parseInt(kimball);
      let total = subFrecuenciaCardiaca + subTotal + subPregCardioRespiratorio + subPregElevacionEnzimas + subDesviacionSegmento;

      console.log( "Edad", edad);
      console.log( "tensionArterial",tensionArterial );
      console.log( "creatinina", creatinina);
      console.log( "kimball", kimball);

      console.log( "subPregCardioRespiratorio", subPregCardioRespiratorio);
      console.log( "subPregElevacionEnzimas", subPregElevacionEnzimas);
      console.log( "subDesviacionSegmento", subDesviacionSegmento);
      console.log( "subFrecuenciaCardiaca", subFrecuenciaCardiaca);

      console.log("TOTAL:", total);
      setTotalPuntos(total);

    }


    /************** [CRUSADE] ***************/

    const setBaselineHandle = event => {
      setBaseline(event.target.value )
    }

    const setCreatinineHandle = event => {
      setCreatinine(event.target.value )
    }

    const setHeartrateHandle = event => {
      setHeartrate(event.target.value )
    }

    const setSexHandle = event => {
      setSex(event.target.checked )
    }

    const setChfHandle = event => {
      setChf(event.target.checked )
    }

    const setPriorVascularDiseaseHandle = event => {
      setPriorVascularDisease(event.target.checked )
    }

    const setDiabetesHandle = event => {
      setDiabetes(event.target.checked )
    }

    const setSystolicBloodHandle = event => {
      setSystolicBlood(event.target.value )
    }

    /************** [HEART SOCRE] ***************/

    const setAnamnesisHandle = event => {
      setAnamnesis(event.target.value )
    }

    const setEcgHandle = event => {
      setEcg(event.target.value )
    }

    const setAgeHandle = event => {
      setAge(event.target.value )
    }

    const setRiskFactorsHandle = event => {
      setRiskFactors(event.target.value )
    }

    const setTroponinHandle = event => {
      setTroponin(event.target.value )
    }

    function recalcularPuntosCrusade() {

      console.log( "Function - RecalcularPuntos - Crusade");

      let subSignsOfCHF = 0;
      let subPriorVascularDisease = 0;
      let subDiabetes = 0;
      let subSex = 0;

      if( chf ) {
        subSignsOfCHF = 7
      }

      if( priorVascularDisease ) {
        subPriorVascularDisease = 6
      }

      if( diabetes ) {
        subDiabetes = 6
      }

      if(sex) {
        subSex = 8;
      }

      let subTotal = parseInt(baseline) + parseInt(creatinine) + parseInt(heartrate) + parseInt(systolicBlood);
      let total = subSignsOfCHF + subPriorVascularDisease + subDiabetes + subTotal + subSex;

      setTotalPuntosCrusade(total);
    }

    function recalcularPuntosHeartScore() {

      console.log( "Function - RecalcularPuntos - Heart");

      let subTotal = parseInt(anamnesis) + parseInt(ecg) + parseInt(age) + parseInt(riskFactors) + parseInt(troponin);
      let total = subTotal;

      setTotalPuntosHeartScore(total);
    }

    function btnLimpiarDatosHeartScore() {
      setAnamnesis('00');
      setEcg('00');
      setAge('00');
      setRiskFactors('00');
      setTroponin('00');
    };


    function btnLimpiarDatosGrace() {
        setEdad('00');
        setFrecuenciaCardiaca('');
        setTensionArterial('00')
        setCreatinina('00')
        setKimball('00')
        setDesviacionSegmentoST(false)
        setElevacionEnzimasCardiacas(false)
        setCardioRespiratorioIngreso(false)
    }

    function btnLimpiarDatosCrusade () {
      setBaseline('00');
      setCreatinine('00');
      setHeartrate('00');
      setSex(false);
      setChf(false);
      setPriorVascularDisease(false);
      setDiabetes(false);
      setSystolicBlood('00');
    }

    function btnLimpiarTodos () {
      btnLimpiarDatosCrusade ()
      btnLimpiarDatosGrace()
      btnLimpiarDatosHeartScore()
    }

    return (

      <>
      <Form>
        <Form.Group className="mb-3" controlId="Edad">
          <Form.Label>Edad</Form.Label>
          <Form.Select value={edad} onChange={edadHandle}>
              <option value="00">Seleccione</option>
              <option value="18">40 - 49 años</option>
              <option value="36">50 - 59 años</option>
              <option value="55">60 - 69 años</option>
              <option value="73">70 - 79 años</option>
              <option value="91">Mas de 80 años</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="FrecuenciaCardiaca">
          <Form.Label>Frecuencia cardíaca</Form.Label>
          <Form.Control type="number" onChange={ (e) => frecuenciaCaridacaHandle(e) } value={frecuenciaCardiaca}  />
        </Form.Group>

        <Form.Group className="mb-3" controlId="TensionArterial">
          <Form.Label>Tensión arterial sistólica</Form.Label>
          <Form.Select value={tensionArterial} onChange={tensionArterialHandle}>
          <option value="00">Seleccione</option>
              <option value="63">Menor a 80 mm/Hg</option>
              <option value="58">80 - 99 mm/Hg</option>
              <option value="47">100 - 119 mm/Hg</option>
              <option value="37">120 - 139 mm/Hg</option>
              <option value="26">140 - 159 mm/Hg</option>
              <option value="11">160 - 169 mm/Hg</option>
              <option value="0">Mayor a 200 mm/Hg</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Creatinina">
          <Form.Label>Creatinina</Form.Label>
          <Form.Select value={creatinina} onChange={creatininaHandle}>
          <option value="00">Seleccione</option>
              <option value="2">Menor o igual a 0,39 mg/dl</option>
              <option value="5">0,4 - 0,79 mg/dl</option>
              <option value="8">0,8 - 1,19 mg/dl</option>0
              <option value="11">1,2 - 1,59 mg/dl</option>
              <option value="14">1,6 - 1,99 mg/dl</option>
              <option value="23">2 - 3,99 mg/dl</option>
              <option value="31">Mayor a 4 mg/dl</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="Kimball">
          <Form.Label>kimball</Form.Label>
          <Form.Select value={kimball} onChange={kimballHandle}>
          <option value="00">Seleccione</option>
              <option value="0">I</option>
              <option value="21">II</option>
              <option value="43">III</option>
              <option value="64">IV</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="CardioRespiratorio">
          {/* <Form.Label>¿Para cardiorrespiratorio al ingreso?</Form.Label> */}
          <Form.Check checked={cardioRespiratorioIngreso} onChange={cardioRespiratorioIngresoHandle} type="switch" id="CardioRespiratorio2" label="¿Para cardiorrespiratorio al ingreso?"/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="ElevacionEnzimas">
          {/* <Form.Label>¿Elevación de enzimas cardiacas?</Form.Label> */}
          <Form.Check checked={elevacionEnzimasCardiacas} onChange={elevacionEnzimasCardiacasHandle} type="switch" id="ElevacionEnzimas" label="¿Elevación de enzimas cardiacas?"/>
        </Form.Group>
       
        <Form.Group className="mb-3" controlId="DesviacionSegmentos">
          {/* <Form.Label>¿Elevación de enzimas cardiacas?</Form.Label> */}
          <Form.Check checked={desviacionSegmentoST} onChange={desviacionSegmentoSTHandle} type="switch" id="DesviacionSegmentos2" label="¿Desviación Segmento ST?"/>
        </Form.Group>
        
        <Alert variant="danger" key={totalPuntos}> Puntaje Grace: {totalPuntos} </Alert>

        <Button variant="primary" type="button" className="mb-3" onClick={btnLimpiarDatosGrace}>Limpiar Grace</Button>
      </Form>

      <div className='divisor'>
        <p>-----------------------------------------------</p>
      </div>

      <Form>
        <Form.Group className="mb-3" controlId="BaselineHematocrit">
          <Form.Label>Baseline Hematocrit</Form.Label>
          <Form.Select value={baseline} onChange={setBaselineHandle}>
          <option value="00">Seleccione</option>
              <option value="9">Menos de 31%</option>
              <option value="7">31% - 33.9%</option>
              <option value="3">34% - 36.9%</option>
              <option value="2">37% - 39.9%</option>
              <option value="0">Igual o mas del 40%</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="CreatinineClearance">
          <Form.Label>Creatinine Clearance</Form.Label>
          <Form.Select value={creatinine} onChange={setCreatinineHandle}>
          <option value="00">Seleccione</option>
              <option value="39">Menor o igual a 15 mL/min</option>
              <option value="35">15 - 30 mL/min</option>
              <option value="28">30 - 60 mL/min</option>
              <option value="17">60 - 90 mL/min</option>
              <option value="7">90 - 120 mL/min</option>
              <option value="0">Más de 120 mL/min</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="HeartRate">
          <Form.Label>Heart Rate</Form.Label>
          <Form.Select value={heartrate} onChange={setHeartrateHandle}>
          <option value="00">Seleccione</option>
              <option value="0">Menos o igual a 70 bpm</option>
              <option value="1">71 - 80 bpm</option>
              <option value="3">81 - 90 bpm</option>0
              <option value="6">91 - 100 bpm</option>
              <option value="8">101 - 110 bpm</option>
              <option value="10">111 - 120 bpm</option>
              <option value="11">Más o igual de 121 bpm</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="setSexHandle">
          {/* <Form.Label>¿Para cardiorrespiratorio al ingreso?</Form.Label> */}
          <Form.Check checked={sex} onChange={setSexHandle} type="switch" id="setSexHandle" label="¿Es mujer?"/>
        </Form.Group> 

        {/* <Form.Group controlId="kindOfStand">
          <Form.Check
            value="design"
            type="radio"
            aria-label="radio 1"
            label="Design"
            onChange={setSexHandle}
            checked={sex === "hombre"}
          />
          <Form.Check
            value="food"
            type="radio"
            aria-label="radio 2"
            label="Food"
            onChange={setSexHandle}
            checked={sex === "mujer"}
          />
        </Form.Group> */}


        <Form.Group className="mb-3" controlId="setChfHandle">
          {/* <Form.Label>¿Elevación de enzimas cardiacas?</Form.Label> */}
          <Form.Check checked={chf} onChange={setChfHandle} type="switch" id="setChfHandle" label="¿Signs of CHF at presentation?"/>
        </Form.Group>
       
        <Form.Group className="mb-3" controlId="setPriorVascularDiseaseHandle">
          {/* <Form.Label>¿Elevación de enzimas cardiacas?</Form.Label> */}
          <Form.Check checked={priorVascularDisease} onChange={setPriorVascularDiseaseHandle} type="switch" id="setPriorVascularDiseaseHandle" label="¿Prior Vascular Disease?"/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="setDiabetesHandle">
          {/* <Form.Label>¿Elevación de enzimas cardiacas?</Form.Label> */}
          <Form.Check checked={diabetes} onChange={setDiabetesHandle} type="switch" id="setDiabetesHandle" label="¿Diabetes Mellitus?"/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="SystolicBloodPressure">
          <Form.Label>Systolic blood pressure</Form.Label>
          <Form.Select value={systolicBlood} onChange={setSystolicBloodHandle}>
              <option value="00">Seleccione</option>
              <option value="10">Menor o igual a 90 mm Hg</option>
              <option value="8">91 - 100 mm Hg</option>
              <option value="5">101 - 120 mm Hg</option>
              <option value="1">121 - 180 mm Hg</option>
              <option value="3">181 - 200 mm Hg</option>
              <option value="5">Más de 201 mm Hg</option>
          </Form.Select>
        </Form.Group>

        <Alert variant="danger" key={totalPuntosCrusade}> Puntaje Crusade: {totalPuntosCrusade} </Alert>

        <Button variant="primary" type="button" className="mb-3" onClick={btnLimpiarDatosCrusade}>Limpiar Crusade</Button>
      </Form>

      <div className='divisor'>
        <p>-----------------------------------------------</p>
      </div>

      

      { /* HEAR SCORE */}
      <Form>
        <Form.Group className="mb-3" controlId="HystoreAnmnesis">
          <Form.Label>Hystore (Anmnesis)</Form.Label>
          <Form.Select value={anamnesis} onChange={setAnamnesisHandle}>
              <option value="00">Seleccione</option>
              <option value="2">Highly suspicious</option>
              <option value="1">Moderately suspicious</option>
              <option value="0">Slightly suspicious</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="ECG">
          <Form.Label>ECG</Form.Label>
          <Form.Select value={ecg} onChange={setEcgHandle}>
              <option value="00">Seleccione</option>
              <option value="2">Significant ST-deviation</option>
              <option value="1">Non-specific repolarisation disturbance / LBBB / PM</option>
              <option value="0">Normal</option>
          </Form.Select>
        </Form.Group>


        <Form.Group className="mb-3" controlId="age">
          <Form.Label>Age</Form.Label>
          <Form.Select value={age} onChange={setAgeHandle}>
              <option value="00">Seleccione</option>
              <option value="2">Más o igual a 65 años</option>
              <option value="1">Entre 45 y 65 años</option>
              <option value="0">Menos de 45 años</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="riskFactors">
          <Form.Label>Risk Factors</Form.Label>
          <Form.Select value={riskFactors} onChange={setRiskFactorsHandle}>
              <option value="00">Seleccione</option>
              <option value="2">3 or more risk factors or history of atherosclerotic disease</option>
              <option value="1">1 or 2 risk factors</option>
              <option value="0">No risk factors known</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="setTroponin">
          <Form.Label>Troponin</Form.Label>
          <Form.Select value={troponin} onChange={setTroponinHandle}>
              <option value="00">Seleccione</option>
              <option value="2">3x or more normal limit</option>
              <option value="1">1-3x normal limit</option>
              <option value="0">Sin o menos normal limit</option>
          </Form.Select>
        </Form.Group>


        <Alert variant="danger" key={totalPuntosHeartScore}> Puntaje Heart: {totalPuntosHeartScore} </Alert>

        <Button variant="primary" type="button" className="mb-3" onClick={btnLimpiarDatosHeartScore}>Limpiar Heart</Button>

        <div className='divisor'>
          <p>-----------------------------------------------</p>
        </div>

        <Button variant="primary" type="button" className="mb-3" onClick={btnLimpiarTodos}>Limpiar todo</Button>

        <Button variant="warning" 
          type="button" 
          className="mb-3 btnEnviarPorWhatsapp" 
          target="_blank"
          href={
            "https://api.whatsapp.com/send?phone=+5492984576953&text=Scores - Grace: [ " + totalPuntos + " ]" +
            " - Crusade: [ " + totalPuntosCrusade + " ] " +
            " - Heart: [ " + totalPuntosHeartScore + " ]."
          }>
            
          Enviar por Whatsapp</Button>

      </Form>

      
      </>

      

    );
  }
  
  export default Scores;