import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './formulario.css';
// import './timepicker.min.css';
// import './timepicker.min.js';

import { useState, useEffect } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// import TimePicker from 'react-time-picker';

function Formulario() {

  const [multiSelections, setMultiSelections] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedIrradiacion, setCheckedIrradiacion] = useState([]);
  const [oportunidad, setOprotundiad] = useState('Desconocido para el (Primera vez)');
  const [asociado, setAsociado] = useState('Mareos');
  const [atenua, setAtenua] = useState('Reposo');
  const [agravantes, setAgravantes] = useState('Sin agravantes');
  const [irradiacion, setIrradiacion] = useState('');
  const [caracter, setCaracter] = useState('Opresivo');
  const [localizacion, setLocalizacion] = useState('Retroesternal');
  const [inicio, setInicio] = useState('Gradual rapido');
  const [encontrarse, setEncontrarse] = useState('Reposo');
  const [edad, setEdad] = useState('');
  const [intensidad, setIntensidad] = useState('8');
  const [nombreApellido, setNombreApellido] = useState('');
  const [sexo, setSexo] = useState('');
  const [horaInicio, setHoraInicio] = useState('10:00');
  // const [horaInicio, setHoraInicio] = useState();
  // const [value, onChange] = useState('10:00');
  const [horaDuracion, setHoraDuracion] = useState('30');
  const [otrasEnfermedades, setOtrasEnfermedades] = useState('')
  const [value, onChange] = useState('10:00');

  const encontrarseHandle = event => {
    setEncontrarse(event.target.value )
  }

  const oportunidadHandle = event => {
    setOprotundiad(event.target.value )
  }

  const atenuaHandle = event => {
    setAtenua(event.target.value )
  }

  const agravantesHandle = event => {
    setAgravantes(event.target.value )
  }

  const irradiacionHandle = event => {
    setIrradiacion(event.target.value )
  }

  const caracterHandle = event => {
    setCaracter(event.target.value )
  }

  const localizacionHandle = event => {
    setLocalizacion(event.target.value )
  }

  const inicioHandle = event => {
    setInicio(event.target.value )
  }

  const asociadoHandle = event => {
    setOprotundiad(event.target.value )
  }

  const edadHandle = event => {
    setEdad(event.target.value )
  }

  const nombreApellidoHandle = event => {
    setNombreApellido(event.target.value);
  }

  const intensidadHandle = event => {
    setIntensidad(event.target.value)
  }

  const sexoHandle = event => {
    setSexo(event.target.value )
  }

  const horaInicioHandle = event => {
    setHoraInicio(event.target.value );
  }

  const horaDuracionHandle = event => {
    setHoraDuracion(event.target.value);
  }

  const otrasEnfermedadesHandle = event => {
    setOtrasEnfermedades(event.target.value);
  }

  var options = [
    
  ];

  var checkList  = [
    'Diabetes I',
    'Diabetes II',
    'HTA',
    'Sobrepeso',
    'Obesidad grado I',
    'Obesidad grado II',
    'Tabaquismo',
    'Ex Tabaquista',
    'Dislipidemia',
    'Cardiopatia isquémica crónica',
    'IAM',
    'Enfermedad Art Periferica',
    'Enolismo',
    'FA ACO',
    'FA NO ACO',
    'ACV Isquemico',
    'ACV Hemorragico',
    'Insuficiencia cardiaca',
    'Muerte súbita',
    'Antecedentes de IAM en la familia en menores de 55 años',
    'Valvulopatia Aortica',
    'Valvulopatia mitral'
  ];

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";
  
  //////////////////////////////////
  var checkListIrradiacion  = [
    'Sin irradiación',
    'Cuello',
    'Mandibula',
    'Brazo izquierdo',
    'Brazo derecho',
    'Epigastrio',
    'Espalda'
  ];

  const handleCheckIrradiacion = (event) => {
    var updatedList = [...checkedIrradiacion];
    if (event.target.checked) {
      updatedList = [...checkedIrradiacion, event.target.value];
    } else {
      updatedList.splice(checkedIrradiacion.indexOf(event.target.value), 1);
    }
    setCheckedIrradiacion(updatedList);
  };

  // Generate string of checked items
  const checkedItemsIrradiacion = checkedIrradiacion.length ? checkedIrradiacion.reduce((total, item) => {
    return total + ", " + item;
  }) : "";

  // Return classes based on whether item is checked
  var isCheckedIrradiacion = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  function btnLimpiarDatos() {
    setEncontrarse('Reposo')
    setOprotundiad('Desconocido para el (Primera vez)')
    setAtenua('Reposo')
    setAgravantes('Sin agravantes')
    setIrradiacion('')
    setCaracter('Opresivo')
    setLocalizacion('Retroesternal')
    setInicio('Gradual rapido')
    setEdad('')
    setNombreApellido('');
    setIntensidad('8')
    setSexo('')
    setHoraInicio('');
    setHoraDuracion('');
    setAsociado('Mareos')
    setChecked( [ ] );
    setCheckedIrradiacion( [ ] );
    setOtrasEnfermedades( '' );
    
    limpiarChecked()

  }

  function limpiarChecked() {
    var elements=document.getElementsByClassName('checkedEnfermedadesSectores');

    Array.prototype.forEach.call(elements, function(element) {
       element.checked = false;
    });

  }

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


  return (
<>
    

    <Form>
      <Form.Group className="mb-3" controlId="nombreApellido">
        <Form.Label>Nombre y Apellido</Form.Label>
        <Form.Control type="text"value={nombreApellido} onChange={nombreApellidoHandle} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="Sexo">
        <Form.Label>Sexo</Form.Label>
        <Form.Select value={sexo} onChange={sexoHandle}>
            <option value="">Seleccionar</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
            <option value="X">X</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="edad">
        <Form.Label>Edad</Form.Label>
        <Form.Control type="number" value={edad} onChange={edadHandle} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="nombreApellido">
        <Form.Label>Hora inicio</Form.Label>
        <Form.Control type="time" value={horaInicio} onChange={horaInicioHandle} />

        {/* <Form.Control type="time" value={horaDuracion} onChange={horaDuracionHandle} /> */}

      </Form.Group>

      <Form.Group className="mb-3" controlId="nombreApellido">
        <Form.Label>Duración (Minutos)</Form.Label>
        <Form.Control type="text" value={horaDuracion} onChange={horaDuracionHandle} />
      </Form.Group>

      <Form.Group className="mb-12" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Form.Label>Enfermedades</Form.Label>
        {checkList.map((item, index) => (
          <div key={index}>
            <input value={item} type="checkbox" onChange={handleCheck} className="checkedEnfermedadesSectores"  />
            <span className={isChecked(item)} style={{ marginLeft: '10px' }}>{item}</span>
          </div>
        ))}
      </Form.Group>
      
      {/* <Form.Group style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Form.Label>Enfermedades</Form.Label>
        <Typeahead
          id="enfermedades"
          labelKey="name"
          multiple
          // onChange={changeEnfermedades}
          onChange={setMultiSelections}
          options={options}
          placeholder="Seleccionar enfermedades..."
          selected={multiSelections}
          allowNew
          newSelectionPrefix="Agregar enfermedad: "
        />
      </Form.Group> */}
      <Form.Group className="mb-3" controlId="nombreApellido">
        <Form.Label>Otras enfermedades ... (Comenzar con ,)</Form.Label>
        <Form.Control type="text" value={otrasEnfermedades} onChange={otrasEnfermedadesHandle} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="Encontrarse">
        <Form.Label>Encontrarse</Form.Label>
        <Form.Select value={encontrarse} onChange={encontrarseHandle}>
            <option value="Reposo">Reposo</option>
            <option value="Actividad de baja intensidad">Actividad de baja intensidad</option>
            <option value="Actividad de intensidad media">Actividad de intensidad media</option>
            <option value="Ingesta">Ingesta</option>
            <option value="Ejercicio">Ejercicio</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="Inicio">
        <Form.Label>Inicio</Form.Label>
        <Form.Select value={inicio} onChange={inicioHandle}>
            <option value="Subito">Subito</option>
            <option value="Gradual lento">Gradual lento</option>
            <option value="Gradual rápido">Gradual rápido</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="Localizacion">
        <Form.Label>Localización</Form.Label>
        <Form.Select value={localizacion} onChange={localizacionHandle}>
            <option value="Retroesternal">Retroesternal</option>
            <option value="en hemitorax izquierdo">en hemitorax izquierdo</option>
            <option value="en hemitorax derecho">en hemitorax derecho</option>
            <option value="Epigastrico">Epigastrico</option>
            <option value="Escapular">Escapular</option>
            <option value="Cuello">Cuello</option>
            <option value="Hombro">Hombro</option>
        </Form.Select>
      </Form.Group>

      {/* Carácter: Opresivo, Punzante, Urente, Inespecifico  */}
      <Form.Group className="mb-3" controlId="Caracter">
        <Form.Label>Carácter</Form.Label>
        <Form.Select value={caracter} onChange={caracterHandle}>
            <option value="Opresivo">Opresivo</option>
            <option value="Punzante">Punzante</option>
            <option value="Urente">Urente</option>
            <option value="Inespecifico">Inespecifico</option>
        </Form.Select>
      </Form.Group>

      {/* <Form.Group className="mb-3" controlId="Irradiacion">
        <Form.Label>Irradiación</Form.Label>
        <Form.Select value={irradiacion} onChange={irradiacionHandle}>
            <option value="Sin irradiación">Sin irradiación</option>
            <option value="Cuello">Cuello</option>
            <option value="Mandibula">Mandibula</option>
            <option value="Brazo izquierdo">Brazo izquierdo</option>
            <option value="Brazo derecho">Brazo derecho</option>
            <option value="Epigastrio">Epigastrio</option>
            <option value="Espalda">Espalda</option>
        </Form.Select>
      </Form.Group> */}

      <Form.Group className="mb-12" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Form.Label>Irradiacion</Form.Label>
        {checkListIrradiacion.map((item, index) => (
          <div key={index}>
            <input value={item} type="checkbox" onChange={handleCheckIrradiacion} className="checkedEnfermedadesSectores"/>
            <span className={isCheckedIrradiacion(item)} style={{ marginLeft: '10px' }}>{item}</span>
          </div>
        ))}
      </Form.Group>


      <Form.Group className="mb-3" controlId="Intensidad">
        <Form.Label>Intensidad</Form.Label>
        <Form.Control type="number" value={intensidad} onChange={intensidadHandle} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="Agravantes">
        <Form.Label>Agravantes</Form.Label>
        <Form.Select value={agravantes} onChange={agravantesHandle}>
            <option value="Sin agravantes">Sin agravantes</option>
            <option value="Respiración profunda">Respiración profunda</option>
            <option value="Movimientos musculares">Movimientos musculares</option>
            <option value="Actividad física">Actividad física</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="Atenua">
        <Form.Label>Atenua</Form.Label>
        <Form.Select value={atenua} onChange={atenuaHandle}>
            <option value="Sin atenuantes">Sin atenuantes</option>
            <option value="Posiciones especificas">Posiciones especificas</option>
            <option value="Posicion fetal">Posicion fetal</option>
            <option value="Reposo">Reposo</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="Asociado">
        <Form.Label>Asociado</Form.Label>
        <Form.Select value={asociado} onChange={asociadoHandle}>
            <option value="Diaforesis">Diaforesis</option>
            <option value="Nauseas">Nauseas</option>
            <option value="Vomitos">Vomitos</option>
            <option value="Sensacion de muerte">Sensacion de muerte</option>
            <option value="Palpitaciones">Palpitaciones</option>
            <option value="Mareos">Mareos</option>
            <option value="Desmayos">Desmayos</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="Oportunidad">
          <Form.Label>Oportunidad</Form.Label>
          <Form.Select value={oportunidad} onChange={oportunidadHandle}>
            <option value='Desconocido para el (Primera vez)'>Desconocido para el (Primera vez)</option>
            <option value="Recurrente">Recurrente</option>
            <option value="Intermitente">Intermitente</option>
            <option value="Simil a IAM previo">Simil a IAM previo</option>
        </Form.Select>
      </Form.Group>

      <p>
        Paciente <strong>{nombreApellido}</strong> de sexo <strong>{sexo}</strong> de <strong>{edad}</strong> años de edad; Con antecedentes de: <strong>{checkedItems} {otrasEnfermedades}</strong>.
        Consulta por guardia el día de la fecha ( <strong>{ date }</strong> ) refiriendo dolor precordial que inicio a las <strong>{horaInicio}</strong> Hs aproximadamente con duración de <strong>{horaDuracion}</strong> minutos aproximadamente.
        Al encontrarse en: <strong>{ encontrarse}</strong>; 
        De inicio: <strong>{inicio}</strong>; 
        De localización: <strong>{localizacion}</strong>; 
        De carácter: <strong>{caracter}</strong>; 
        Con irradiación a: <strong>{checkedItemsIrradiacion}</strong>; 
        de Intesidad: <strong>{intensidad}</strong>; 
        Se agrava con: <strong>{agravantes}</strong>;
        Y se atenúa con: <strong>{atenua}</strong>; 
        Asociado a: <strong>{asociado}</strong>;
        Refiere que el dolor es <strong>{oportunidad}</strong>.
      </p>
      

    
      <Button variant="primary" type="button" className="mb-3" onClick={btnLimpiarDatos}>Limpiar datos</Button>
      <Button variant="primary" 
      type="button" 
      className="mb-3 btnEnviarPorWhatsapp" 

      href={
        "https://api.whatsapp.com/send?phone=+5492984576953&text=Paciente " + nombreApellido +
        " de sexo: " + sexo  + ";" +
        " de " + edad + " años de edad;" +
        " Con antecedentes de: " + checkedItems + otrasEnfermedades + "." +
        " Consulta por guardia el día de la fecha ("+  date  + ") refiriendo dolor precordial que inicio a las " + horaInicio + " Hs aproximadamente." + 
        " Con una duración de " + horaDuracion + " minutos aproximadamente. " +
        " Al encontrarse en " + encontrarse + ", " +
        "de inicio " + inicio + ", " +
        "de localización: " + localizacion + "; " +
        "De carácter: " + caracter + "; " +
        "Con irradiación a: " + checkedItemsIrradiacion + "; " +
        "de Intesidad: " + intensidad + "; " +
        "Se agrava con: " + agravantes + "; " +
        "Y se atenúa con: " + atenua + "; " +
        "Asociado a: " + asociado + "; " +
        "Refiere que el dolor es: " + oportunidad + "."
      } target="_blank">
        
      Enviar por Whatsapp</Button>

    </Form>

    </>
  );
  }
  
  export default Formulario;