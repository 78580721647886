import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Inicio from './Components/inicio/inicio';
import Menu from './Components/menu/menu';
import Scores from './Components/scores/scores';

import { Container } from "react-bootstrap";

function App() {
  return (

    <div className="App">
      <Menu/>
      <Container>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="scores" element={<Scores />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </div>

    
  );

  

}

export default App;
